import React from 'react';
import { navigate } from 'gatsby';
import ExploreButton from './ExploreButton';
import ICON_CODE from '../../../images/icon-code.png';
import ICON_DESIGN from '../../../images/icon-design.png';
import ICON_WORK from '../../../images/icon-work.png';
import ICON_TECHNICAL from '../../../images/icon-technical.png';
import ICON_ARROW_RIGHT from '../../../images/icon-arrow-right.png';
import './HomeServices.scss';

interface SectionCellProps {
  section: string;
  mod?: string;
  children?: React.ReactNode;
}

const HomeServices = (): JSX.Element => {
  const onServiceEnter = (id: string) => {
    const exploreBtn = document.getElementById(`services-explore-${id}`);
    if (exploreBtn) {
      exploreBtn.style.opacity = '1';
      exploreBtn.style.pointerEvents = 'all';
    }
  };
  const onServiceLeave = (id: string) => {
    const exploreBtn = document.getElementById(`services-explore-${id}`);
    if (exploreBtn) {
      exploreBtn.style.opacity = '0';
      exploreBtn.style.pointerEvents = 'none';
    }
  };

  const SectionCell = ({ section, mod, children }: SectionCellProps): JSX.Element => (
    <div
      className={`home-section-cell ${mod ? `mod-${mod}` : ''}`}
      onMouseEnter={() => onServiceEnter(section)}
      onMouseLeave={() => onServiceLeave(section)}
    >
      {children}
    </div>
  );

  return (
    <section className="home-section" id="services">
      <div className="home-section-container">
        {/* Desktop View */}
        <div className="home-section-table">
          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <SectionCell section="technical-consulting" mod="img">
              <img src={ICON_TECHNICAL} alt="" />
            </SectionCell>
            <SectionCell section="technical-consulting" mod="text">
              <h2>Technical Consulting</h2>
            </SectionCell>

            <SectionCell section="development-and-engineering" mod="img">
              <img src={ICON_CODE} alt="" />
            </SectionCell>
            <SectionCell section="development-and-engineering" mod="text">
              <h2>Development & Engineering</h2>
            </SectionCell>
          </div>

          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <SectionCell section="technical-consulting" mod="empty" />
            <SectionCell section="technical-consulting" mod="text">
              <p>
                From security enhancement to cloud migration, from data analytics to performance tuning, App Bar's
                consultants help you understand the problems you are facing. On some situation, we may...
              </p>
              <ExploreButton section="technical-consulting" />
            </SectionCell>

            <SectionCell section="development-and-engineering" mod="empty" />
            <SectionCell section="development-and-engineering" mod="text">
              <p>
                We are the disciple of modern software engineering principles: CRUD, REST, and DRY (Don't repeat
                yourself). App Bar takes different approaches to the development process, based on different project
                needs...
              </p>
              <ExploreButton section="development-and-engineering" />
            </SectionCell>
          </div>

          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <SectionCell section="ui-ux" mod="img">
              <img src={ICON_DESIGN} alt="" />
            </SectionCell>
            <SectionCell section="ui-ux" mod="text">
              <h2>UI/UX Design</h2>
            </SectionCell>

            <SectionCell section="our-product" mod="img">
              <img src={ICON_WORK} alt="" />
            </SectionCell>
            <SectionCell section="our-product" mod="text">
              <h2>Our Product</h2>
            </SectionCell>
          </div>

          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <SectionCell section="ui-ux" mod="empty" />
            <SectionCell section="ui-ux" mod="text">
              <p>
                When it comes to design, it is never only about creativity - it consists of a process of evaluation,
                analysis, research, user interview, and finally visualize the design.
              </p>
              <ExploreButton section="ui-ux" />
            </SectionCell>

            <SectionCell section="our-product" mod="empty" />
            <SectionCell section="our-product" mod="text">
              <p>
                <em>Employee engagement platform:</em>
                <br />
                Mission to put employees and leaders together and enhance the engagement rate through gamification...
              </p>
              <ExploreButton section="our-product" />
            </SectionCell>
          </div>
        </div>

        {/* Mobile View */}
        <div className="services-mobile-list">
          <div className="services-table-cell" data-sal="slide-up" data-sal-duration="600">
            <img src={ICON_TECHNICAL} alt="" />
            <div className="services-table-cell-text">
              <h2>Technical Consulting</h2>
              <p>
                {'From security enhancement to cloud migration, from data analytics to performance tuning, App Bar… '}
                <a href="/about#technical-consulting">Explore</a>
              </p>
            </div>
          </div>
          <div className="services-table-cell" data-sal="slide-up" data-sal-duration="600">
            <img src={ICON_CODE} alt="" />
            <div className="services-table-cell-text">
              <h2>Development & Engineering</h2>
              <p>
                {'We are the disciple of modern software engineering principles: CRUD, REST, and DRY… '}
                <a href="/about#development-and-engineering">Explore</a>
              </p>
            </div>
          </div>
          <div className="services-table-cell" data-sal="slide-up" data-sal-duration="600">
            <img src={ICON_DESIGN} alt="" />
            <div className="services-table-cell-text">
              <h2>UI/UX Design</h2>
              <p>
                {
                  'When it comes to design, it is never only about creativity - it consists of a process of evaluation… '
                }
                <a href="/about#ui-ux">Explore</a>
              </p>
            </div>
          </div>
          <div className="services-table-cell" data-sal="slide-up" data-sal-duration="600">
            <img src={ICON_WORK} alt="" />
            <div className="services-table-cell-text">
              <h2>Our Product</h2>
              <p>
                <em>Employee engagement platform:</em>
                <br />
                {'Mission to put employees and leaders together and… '}
                <a href="/about#our-product">Explore</a>
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="services-about-us home-more-button"
          onClick={() => {
            navigate('/about');
          }}
          data-sal="fade"
          data-sal-duration="600"
        >
          <div>
            <p>About us</p>
            <img src={ICON_ARROW_RIGHT} alt="" />
          </div>
        </button>
      </div>
    </section>
  );
};

export default HomeServices;
