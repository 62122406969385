import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import './HomeAbout.scss';

const QUERIES = graphql`
  query {
    hoverFiles: allFile(filter: { relativeDirectory: { eq: "hover" } }) {
      hoverImgs: nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
          id
        }
      }
    }
    mainBanner: file(name: { eq: "home-office2" }, ext: { eq: ".jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

interface QueryResults {
  hoverFiles: { hoverImgs: { childImageSharp: { gatsbyImageData: IGatsbyImageData; id: string } }[] };
  mainBanner: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
}

const HomeAbout = (): JSX.Element => {
  const {
    hoverFiles: { hoverImgs },
    mainBanner: {
      childImageSharp: { gatsbyImageData: mainBannerImg },
    },
  }: QueryResults = useStaticQuery(QUERIES);
  const [showHover, setShowHover] = useState(false);
  const [hoverInterval, setHoverInterval] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (showHover && hoverInterval) {
      return;
    }
    const srcList: string[] = hoverImgs.map((hoverImg) => hoverImg.childImageSharp.id);
    const swapImg = () => {
      // Get all hover images element
      const imgs = Array.from(
        (document.querySelectorAll('.home-section-img-hover') as unknown) as HTMLCollectionOf<HTMLDivElement>,
      );
      const currentIdx = imgs.findIndex((img) => img.style.opacity === '1');
      // Hide current image element
      if (currentIdx > -1) {
        imgs[currentIdx].style.opacity = '0';
      }
      // Find next image element
      let nextIdx: number;
      if (currentIdx < 0 || currentIdx === srcList.length - 1) {
        nextIdx = 0;
      } else {
        nextIdx = currentIdx + 1;
      }
      const nextImg = imgs[nextIdx];
      // Show next image element
      if (nextImg) {
        nextImg.style.opacity = '1';
      }
    };
    if (showHover) {
      setHoverInterval(setInterval(swapImg, 100));
    } else if (hoverInterval) {
      clearInterval(hoverInterval);
      setHoverInterval(undefined);
      // Hide all hover image elements
      const imgs = document.querySelectorAll('.home-section-img-hover');
      Array.from((imgs as unknown) as HTMLCollectionOf<HTMLDivElement>).forEach((img) => {
        const temp = img;
        temp.style.opacity = '0';
      });
    }
  }, [hoverImgs, hoverInterval, showHover]);

  return (
    <section className="home-section" id="about">
      <div className="home-section-container">
        <div className="home-section-about-banner" data-sal="slide-left" data-sal-duration="600">
          <div className="home-section-about-text-container">
            <h1 data-sal="slide-right" data-sal-duration="600" data-sal-delay="200">
              WE DEVELOP
            </h1>
            <p data-sal="slide-right" data-sal-duration="600" data-sal-delay="200">
              <em
                onMouseOver={() => setShowHover(true)}
                onTouchStart={() => setShowHover(true)}
                onFocus={() => setShowHover(true)}
                onMouseLeave={() => setShowHover(false)}
                onTouchEnd={() => setShowHover(false)}
                onBlur={() => setShowHover(false)}
              >
                Great things
              </em>
              {' happen in this bar.'}
            </p>
          </div>
          <div className="home-section-img-main-container">
            <GatsbyImage className="home-section-img-main" image={mainBannerImg} alt="We Develop Great Things" />
            <div className="home-section-img-hover-wrapper">
              {hoverImgs.map(({ childImageSharp: { gatsbyImageData, id } }) => (
                <GatsbyImage className="home-section-img-hover" key={id} image={gatsbyImageData} alt="" />
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="home-section-img" data-sal="slide-left" data-sal-duration="600">
        <GatsbyImage className="home-section-img-main" image={mainBannerImg} alt="We Develop Great Things" />
        <div className="home-section-img-hover-wrapper">
          {hoverImgs.map(({ childImageSharp: { gatsbyImageData, id } }) => (
            <GatsbyImage className="home-section-img-hover" key={id} image={gatsbyImageData} alt="" />
          ))}
        </div>
      </div> */}
    </section>
  );
};

export default HomeAbout;
