import React from 'react';
import { navigate } from 'gatsby';
import HOME_EYE from '../../../images/home-eye.png';
import './ExploreButton.scss';

interface Props {
  section: string;
}

const ExploreButton = ({ section }: Props): JSX.Element => (
  <button
    type="button"
    id={`services-explore-${section}`}
    className="services-explore"
    onClick={() => {
      navigate(`/about#${section}`);
    }}
  >
    <img src={HOME_EYE} alt="" />
    <p>Explore</p>
  </button>
);

export default ExploreButton;
