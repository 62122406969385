import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import './OurPartners.scss';

const QUERIES = graphql`
  query {
    partnerFiles: allFile(filter: { relativeDirectory: { eq: "partners" } }) {
      partnerLogos: nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 300, placeholder: BLURRED)
          id
        }
        name
      }
    }
  }
`;

interface QueryResults {
  partnerFiles: {
    partnerLogos: {
      childImageSharp: { gatsbyImageData: IGatsbyImageData; id: string };
      name: string;
    }[];
  };
}

const OurPartners = (): JSX.Element => {
  const {
    partnerFiles: { partnerLogos },
  }: QueryResults = useStaticQuery(QUERIES);

  return (
    <section className="home-section" id="partners">
      <div className="home-section-container">
        <div className="home-section-text">
          <h2 data-sal="slide-up" data-sal-duration="600">
            Our Clients
          </h2>
        </div>
        <div className="partners-list" data-sal="slide-up" data-sal-duration="600">
          {partnerLogos.map(({ childImageSharp: { gatsbyImageData, id }, name }) => (
            <GatsbyImage className="partners-item" key={id} image={gatsbyImageData} alt={name} objectFit="contain" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurPartners;
