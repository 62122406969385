import React from 'react';
import HOME_JACK from '../../../images/home-jack.png';
import HOME_WILLIAM from '../../../images/home-william.png';
import HOME_GEO1 from '../../../images/home-geo1.gif';
import HOME_GEO2 from '../../../images/home-geo2.gif';
import './OurTeam.scss';

const OurTeam = (): JSX.Element => (
  <section className="home-section" id="our-team">
    <div className="home-section-container">
      <div className="home-section-text">
        <h2 data-sal="slide-up" data-sal-duration="600">
          Your End-to-End App Development Team
        </h2>
        <p data-sal="slide-up" data-sal-duration="600">
          App Bar delivers creative and technical mobile software solutions to companies from SMEs to international
          corporates. Our service scope ranges from UI/UX design, gamification, customized development and cloud-based
          solutions. Based in Hong Kong, we are looking beyond to visualize the digital visions of organizations across
          APAC, with a blend of creativity and technology.
        </p>
      </div>
      <div className="our-team-section-list">
        <img className="our-team-animation" data-sal="fade" data-sal-duration="600" src={HOME_GEO1} alt="" />
        <div className="our-team-section-list-item" data-sal="slide-up" data-sal-duration="600">
          <img className="our-team-section-list-item-profile-pic" src={HOME_JACK} alt="Hinlok Cheung" />
          <p className="our-team-section-list-item-name">Hinlok Cheung</p>
          <p className="our-team-section-list-item-position">Cofounder & Business Director</p>
        </div>
        <div className="our-team-section-list-item" data-sal="slide-up" data-sal-duration="600">
          <img className="our-team-section-list-item-profile-pic" src={HOME_WILLIAM} alt="William Lo" />
          <p className="our-team-section-list-item-name">William Lo</p>
          <p className="our-team-section-list-item-position">Cofounder & Technical Director</p>
        </div>
        <img className="our-team-animation" data-sal="fade" data-sal-duration="600" src={HOME_GEO2} alt="" />
      </div>
    </div>
  </section>
);

export default OurTeam;
