import React, { useRef } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import ICON_ARROW_RIGHT from '../../../images/icon-arrow-right.png';
import HorizontalScrollbar from '../../../components/HorizontalScrollbar/HorizontalScrollbar';
import ProjectCard from '../../../components/ProjectCard/ProjectCard';
import Project from '../../../models/Project';
import './OurProjects.scss';

const QUERIES = graphql`
  {
    allProjectsJson {
      edges {
        node {
          projectId
          cardTitle
          services
          background
          color
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

interface QueryResults {
  allProjectsJson: { edges: { node: Project }[] };
}

const OurProjects = (): JSX.Element => {
  const {
    allProjectsJson: { edges },
  }: QueryResults = useStaticQuery(QUERIES);

  const projectsListRef = useRef<HTMLDivElement>(null);

  return (
    <section className="home-section" id="projects">
      <div className="home-section-container">
        <div className="home-section-text">
          <h2 data-sal="slide-up" data-sal-duration="600">
            Our Projects
          </h2>
          <div className="projects-desc-wrapper" data-sal="slide-up" data-sal-duration="600">
            <p>We elevate our clients’ brands and apps with UI/UX design, app/web development and gamification.</p>
            <button
              data-sal="fade"
              data-sal-duration="600"
              type="button"
              className="projects-all-projects home-more-button"
              onClick={() => {
                navigate('/work');
              }}
            >
              <div>
                <p>All projects</p>
                <img src={ICON_ARROW_RIGHT} alt="" />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="projects-list-container" ref={projectsListRef}>
        <div className="projects-list">
          {(edges as { node: Project }[]).map(({ node }, i: number) => (
            <React.Fragment key={node.projectId}>
              <ProjectCard
                title={node.cardTitle}
                desc={node.services}
                imgFluid={node.thumbnail.childImageSharp.gatsbyImageData}
                background={node.background}
                color={node.color}
                data-sal="zoom-in"
                data-sal-duration="300"
                data-sal-delay={`${i > 2 ? 0 : i * 50}`}
                data-sal-easing="ease-in-out"
                onClick={() => navigate(`/work/${node.projectId}`)}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="home-section-container">
        <HorizontalScrollbar targetRef={projectsListRef} />
        <button
          data-sal="fade"
          data-sal-duration="600"
          type="button"
          className="projects-all-projects-mobile home-more-button"
          onClick={() => {
            navigate('/work');
          }}
        >
          <div>
            <p>All projects</p>
            <img src={ICON_ARROW_RIGHT} alt="" />
          </div>
        </button>
      </div>
    </section>
  );
};

export default OurProjects;
