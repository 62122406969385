import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import './ProjectCard.scss';

interface Props {
  title: string;
  desc: string;
  img?: string;
  imgFluid?: IGatsbyImageData;
  background: string;
  color: string;
  onClick?: () => void;
}

const ProjectCard = ({ title, desc, img, imgFluid, background, color, onClick, ...others }: Props): JSX.Element => (
  <button type="button" {...others} className="projects-list-card" onClick={onClick}>
    <div className="projects-list-card-background" style={{ backgroundColor: background }} />
    <div className="projects-list-card-content">
      {img && <img className="projects-list-card-img" src={img} alt="" />}
      {imgFluid && <GatsbyImage image={imgFluid} alt={desc} className="projects-list-card-img" />}
      <h3 style={{ color }}>{title}</h3>
      <p style={{ color }}>{desc}</p>
    </div>
  </button>
);

export default ProjectCard;
