import React from 'react';
import Layout from '../layouts/layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import WavingHand from '../components/WavingHand/WavingHand';
import HomeAbout from '../components/HomeSections/HomeAbout/HomeAbout';
import HomeServices from '../components/HomeSections/HomeServices/HomeServices';
import OurTeam from '../components/HomeSections/OurTeam/OurTeam';
import OurPartners from '../components/HomeSections/OurPartners/OurPartners';
import OurProjects from '../components/HomeSections/OurProjects/OurProjects';
import './index.scss';

const Home = (): JSX.Element => (
  <Layout>
    <div className="home">
      <Header />

      <HomeAbout />
      <HomeServices />
      <OurTeam />
      <OurPartners />
      <OurProjects />

      <WavingHand />

      <Footer />
    </div>
  </Layout>
);

export default Home;
